
import React from 'react';
import Navbar from '../components/Navbar.js';
import Footer from '../components/Footer.js';
import { Link } from 'react-router-dom';
import Slider from '../components/Slider.js';
import Recommend from '../components/Recommend.js';
const Home = () => {
    document.title = 'Home';
    return (
        <>
            <Navbar />
           <Slider />
           <Recommend />
            <div className="container mt-4">
                <h1>Home Page</h1>
                <Link to="/about">About Page</Link>
                <p>This is the content of the home page.</p>
            </div>
            <Footer />
        </>
    );
}

export default Home;