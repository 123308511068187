import React, { useState, useEffect } from 'react';
import axios from 'axios';
import placeholderImage from '../components/img/post1.jpg';
import Navbar from '../components/Navbar.js';
import BASE_URL from '../components/Baseurl.js';
import Footer from '../components/Footer.js';

const Allevents = () => {
  const [events, setEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const eventsPerPage = 8;

  useEffect(() => {
    // Set page title
    document.title = 'Events';

    // Function to fetch events
    const fetchEvents = async () => {
      try {
        const response = await axios.get(`${BASE_URL}fetch-events.php`);
        setEvents(response.data); // Assuming response.data is an array of events
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, []);

  // Pagination logic
  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = events.slice(indexOfFirstEvent, indexOfLastEvent);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <Navbar />
      <div className="subbody">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-12 col-xl-3">
              <h2 className="hd1 mt-4">Filter</h2>
              <div className="card p-2">
                <div className="hd5">Event Type</div>
                <div className="mt-2">
                    <div className="flopt1">Exhibition</div>
                    <div className="flopt1">Conference</div>
                    <div className="flopt1">Workshop</div>
                    <div className="flopt1">Meetup</div>
                    <div className="flopt1">Concert</div>
                    <div className="flopt1">Sports</div>
                </div>
              </div>
              {/* Add filter options here */}
            </div>
            <div className="col-md-9 col-12 col-xl-9">
              <h2 className="hd1 mt-4">Events</h2>
              <div className="row">
                {currentEvents.map((event) => (
                  <div key={event.id} className="col-md-3 col-4 mb-4">
                    <div className="">
                      <img
                        src={`${BASE_URL}img/event/${event.event_banner}`}
                        onError={(e) => { e.target.onerror = null; e.target.src = placeholderImage }}
                        className="card-img-top img-fluid mxig"
                        alt={`banner-${event.id}`}
                      />
                    </div>
                    <div className="hd2">{event.event_name}</div>
                    <div className="hd3">{event.event_description}</div>
                  </div>
                ))}
              </div>
              {/* Pagination */}
              <div className="row mt-4">
                <div className="col-12 d-flex justify-content-center">
                  <nav>
                    <ul className="pagination">
                      <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => paginate(currentPage - 1)}>Previous</button>
                      </li>
                      {Array.from({ length: Math.ceil(events.length / eventsPerPage) }, (_, index) => (
                        <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                          <button className="page-link" onClick={() => paginate(index + 1)}>{index + 1}</button>
                        </li>
                      ))}
                      <li className={`page-item ${currentPage === Math.ceil(events.length / eventsPerPage) ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => paginate(currentPage + 1)}>Next</button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Allevents;
