import logo from './logo.svg';
import './App.css';
import './components/css/common.css';
import './components/css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
 

import Home from './Pages/Home';
import About from './Pages/About';
import Login from './Pages/visitor/Login';
import Topshows from './Pages/Topshows';
import Allevents from './Pages/Allevents';

function App() {
  return (
    <div>
      
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/About' element={<About />} />
      <Route path='/Login' element={<Login />} />
      <Route path='/Topshows' element={<Topshows />} />
      <Route path='/Allevents' element={<Allevents />} />
    </Routes>
    </BrowserRouter>

    </div>
  );
}

export default App;
