import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BASE_URL from '../../components/Baseurl';

const Login = () => {
  const [emailOrPhone, setEmailOrPhone] = useState('');
  const [password, setPassword] = useState('');
  const [ipAddress, setIpAddress] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json'); // External API to get the IP address
        setIpAddress(response.data.ip);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    fetchIpAddress();
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BASE_URL}login-visitor.php`, {
        email_or_phone: emailOrPhone,
        password: password,
        ip_address: ipAddress,
      });
      if (response.data.success) {
        console.log('Login successful:', response.data);
        // Handle successful login (e.g., save token, redirect)
      } else {
        setError(response.data.message);
      }
    } catch (err) {
      console.error('Error logging in:', err);
      setError('Invalid email or password');
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="container mt-4">
      <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <div className="row px-5 py-2">
          {error && (
            <div className="col-md-12 mb-3 text-center invalid-bd">
              <div className="invalid-log">~~ {error} ~~</div>
            </div>
          )}

          <div className="col-md-12 mb-3">
            <label htmlFor="email_or_phone" className="citycls mb-2">Email or Phone</label>
            <input
              type="text"
              className="form-control input-form"
              name="email_or_phone"
              id="email_or_phone"
              placeholder="Enter Email or Phone"
              value={emailOrPhone}
              onChange={(e) => setEmailOrPhone(e.target.value)}
              required
            />
          </div>

          <div className="col-md-12">
            <label htmlFor="mypassword" className="citycls mb-2">Password</label>
            <input
              type={showPassword ? 'text' : 'password'}
              className="form-control input-form"
              name="password"
              id="mypassword"
              placeholder="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <div className="form-check">
              <input
                className="form-check-input chck-pw"
                type="checkbox"
                value=""
                id="mypasswordToggle"
                onClick={toggleShowPassword}
              />
              <label className="form-check-label show-pw" htmlFor="mypasswordToggle">Show Password</label>
            </div>
          </div>

          <div className="col-md-12 text-end">
            {/* Uncomment the line below if you have a "Forgot Password" functionality */}
            {/* <a href="forgot-password"><small className="forget-pw">Forget Password?</small></a> */}
          </div>

          <div className="col-md-12 text-center mt-4">
            <button className="btn btn-primary" type="submit">Login</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
