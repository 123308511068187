import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../components/img/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faBars } from '@fortawesome/free-solid-svg-icons';


const Navbar = () => {
  return (
    <header>
      <nav className="sticky-top">
        <div className="nav_1">
          <div className="nav_width">
            <div className="nav_1_sub">
              <Link to="/"><img src={logo} alt="logo" /></Link>
              <div className="search_bar">
                <input placeholder="Search by Event, Industry, More..." className='input1' />
                <FontAwesomeIcon icon={faMagnifyingGlass} className="search_icon" />
              </div>
            </div>

            <form>
              <select>
                <option value="" defaultValue>Ahmedabad</option>
                <option value="">Delhi</option>
                <option value="">Mumbai</option>
                <option value="">Hyderabad</option>
                <option value="">Bengaluru</option>
                <option value="">Pune</option>
              </select>
              <Link to="/login" id="login"><button>Login</button></Link>
              <FontAwesomeIcon icon={faBars} />
            </form>
          </div>

          <div className="nav_1_resp">
            <div className="nav1_l">
              <h2>It All Starts Here!</h2>
              <span>Ahmedabad ›</span>
            </div>
            <form>
              <button>Use App</button>
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </form>
          </div>
        </div>

        <div className="nav_2">
          <div className="nav_width">
            <ul className="nav_2_sub1 m-0">
              <li><Link to="/allevents">All Events</Link></li>
              <li><Link to="/allevents">Conference</Link></li>
              <li><Link to="/topshows">Top Shows</Link></li>
              <li><Link to="/events">Top Venues</Link></li>
              <li><Link to="/plays">Hubs & Community</Link></li>
              <li><Link to="/sports">Sports</Link></li>
            
            </ul>
            <ul className="nav_2_sub2 m-0">
         
      
              <li><Link to="#">Organiser Login</Link></li>
              <li><Link to="#">Add Events</Link></li>
            </ul>
          </div>
          <div className="nav2_resp">
            <Link to="/movies"><img src="./Assets/Navbar/img1.png" alt="nav1" /></Link>
            <Link to="/sports"><img src="./Assets/Navbar/img2.avif" alt="nav2" /></Link>
            <Link to="/icc_men"><img src="./Assets/Navbar/img3.avif" alt="nav3" /></Link>
            <Link to="/stream"><img src="./Assets/Navbar/img4.avif" alt="nav4" /></Link>
            <Link to="/plays"><img src="./Assets/Navbar/img5.avif" alt="nav5" /></Link>
            <Link to="/events"><img src="./Assets/Navbar/img6.avif" alt="nav6" /></Link>
            <Link to="/activities"><img src="./Assets/Navbar/img7.avif" alt="nav7" /></Link>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Navbar;