import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Assuming axios is used for HTTP requests
import placeholderImage from '../components/img/post1.jpg'; // Placeholder image
import Navbar from '../components/Navbar.js';
import BASE_URL from '../components/Baseurl.js';
import Footer from '../components/Footer.js';

const Topshows = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    // Function to fetch events
    const fetchEvents = async () => {
      try {
 
        const response = await axios.get(`${BASE_URL}fetch-top-shows.php`);
        setEvents(response.data); // Assuming response.data is an array of events
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, []);

  return (

 <>
<Navbar />
<div className="subbody">
<div className="container ">
      <div className="card_heading ">
        <h2 className="hd1 mt-4">
          Top Shows
        </h2>
        <div className="see_all">
           
        </div>
      </div>
      <div className="row">
        {events.map((event) => (
          <div key={event.id} className="col-md-2 col-4 mb-4">
            <div className="">
              <img
                src={`${BASE_URL}${event.event_banner}`}
                onError={(e) => { e.target.onerror = null; e.target.src = placeholderImage }}
                className="card-img-top img-fluid"
                alt={`banner-${event.id}`}
              />
            </div>
            <div className="hd2">{event.event_name}</div>
            <div className="hd3">{event.event_description}</div>
          </div>
        ))}
      </div>
    </div>
</div>
    
<Footer/>
    </>
  );
};

export default Topshows;