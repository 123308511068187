import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Assuming axios is used for HTTP requests
import placeholderImage from '../components/img/post1.jpg'; // Placeholder image
import { Link } from 'react-router-dom';
import BASE_URL from '../components/Baseurl';

const Recommend = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Function to fetch events
    const fetchEvents = async () => {
      try {
        const response = await axios.get(`${BASE_URL}fetch-featured-events.php`);
        const eventsData = Array.isArray(response.data) ? response.data : [];
        setEvents(eventsData);
      } catch (error) {
        console.error('Error fetching events:', error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchEvents();
  }, []);

  if (loading) {
    return (
      <div className="container mt-4">
        <div className="card_heading">
          <h2 className="hd1">
            Featured Events
          </h2>
          <Link to="/allevents" className="atag">
            <div className="see_all">
              <span>See All ›</span>
            </div>
          </Link>
        </div>
        <div className="row">
          {Array.from({ length: 6 }).map((_, index) => (
            <div key={index} className="col-6 col-md-2 mb-4">
              <div className="loading-placeholder">
                <div className="loading-image-placeholder"></div>
                <div className="loading-text-placeholder"></div>
                <div className="loading-text-placeholder"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-4">
      <div className="card_heading">
        <h2 className="hd1">
          Featured Events
        </h2>
        <Link to="/allevents" className="atag">
          <div className="see_all">
            <span>See All ›</span>
          </div>
        </Link>
      </div>
      <div className="row">
        {events.map((event) => (
          <div key={event.id} className="col-6 col-md-2 mb-4">
            <div>
              <img
                src={`${BASE_URL}img/event/${event.event_banner}`}
                onError={(e) => { e.target.onerror = null; e.target.src = placeholderImage }}
                className="card-img-top img-fluid"
                alt={event.event_name}
              />
            </div>
            <div className="hd2">{event.event_name}</div>
            <div className="hd3">{event.event_description}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Recommend;