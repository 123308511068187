import React, { useEffect } from 'react';
 
import ban1 from '../components/img/ban1.jpg';
import ban2 from '../components/img/ban2.jpg';
import ban3 from '../components/img/ban3.jpg';
import ban4 from '../components/img/ban4.jpg';

const Slider = () => {
    useEffect(() => {
      const images = [ban1, ban2];
      let currentIndex = 0;
      const slideContainer = document.getElementById('slide-container');
      const indicators = document.querySelectorAll('.indicator');
  
      const showSlide = (index) => {
        slideContainer.style.transform = `translateX(-${index * 100}%)`;
        indicators.forEach((indicator, idx) => {
          indicator.classList.toggle('active', idx === index);
        });
      };
  
      const interval = setInterval(() => {
        currentIndex = (currentIndex + 1) % images.length;
        showSlide(currentIndex);
      }, 3000); // Change slide every 3 seconds
  
      showSlide(currentIndex); // Initial slide
  
      return () => clearInterval(interval);
    }, []);
  
    const goToSlide = (index) => {
      document.getElementById('slide-container').style.transform = `translateX(-${index * 100}%)`;
      document.querySelectorAll('.indicator').forEach((indicator, idx) => {
        indicator.classList.toggle('active', idx === index);
      });
    };
  
    return (
      <div className="slider">
        <div id="slide-container" className="slide-container">
          <img className="slide" src={ban1} alt="banner1" />
          <img className="slide" src={ban2} alt="banner2" />
        </div>
        <div className="indicators">
          {[ban1, ban2].map((_, index) => (
            <span
              key={index}
              className={`indicator ${index === 0 ? 'active' : ''}`}
              onClick={() => goToSlide(index)}
            ></span>
          ))}
        </div>
      </div>
    );
  };
  
  export default Slider;