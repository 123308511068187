import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar.js';

const About = () => {
    return (
        <> <Navbar />
            <div className="container mt-4">
                <Link to="/">About Page</Link>
                <p>This is the content of the about page.</p>
            </div>
        </>
    );
}

export default About;
